// src/components/PostList.js
import React from 'react';
import { Link } from 'react-router-dom';
import posts from '../posts/posts.json';

const PostList = () => {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '40px 20px', fontFamily: 'sans-serif', color: '#333', backgroundColor: '#fff' }}>
      <h1 style={{ fontSize: '40px', fontWeight: 'normal', margin: '0 0 40px 0', lineHeight: '1.1' }}>Posts</h1>
      {posts.map((post) => (
        <div key={post.id} style={{ marginBottom: '20px' }}>
          <Link to={`/posts/${post.id}`} style={{ fontSize: '24px', fontWeight: 'bold', color: '#0000EE', textDecoration: 'none' }}>
            {post.title} - {post.date}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default PostList;