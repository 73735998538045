import React from 'react';
import MiroImage from '../images/miro.JPG'; 
import { Link } from 'react-router-dom'; 


const Jamiro = () => {
  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '40px 20px',
    fontFamily: 'sans-serif',
    color: '#333',
    backgroundColor: '#fff',
  };

  const headingStyle = {
    fontSize: '40px',
    fontWeight: 'normal',
    margin: '0 0 40px 0',
    lineHeight: '1.1',
  };

  const sectionTitleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '40px',
    marginBottom: '20px',
    fontStyle: 'italic',
  };

  const linkStyle = {
    color: '#0000EE',
    textDecoration: 'none',
  };

  const listStyle = {
    marginTop: '0',
    marginBottom: '40px',
    paddingLeft: '0',
    listStyle: 'none',
  };

  const listItemStyle = {
    marginBottom: '8px',
    fontSize: '18px',
    lineHeight: '1.5',
  };

  const imageStyle = {
    width: '200px',  // Adjust size as needed
    height: 'auto',
    marginTop: '20px',
    marginBottom: '40px',
  };

  

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Hi,<br />I'm Jamir Ferguson</h1>

      <img src={MiroImage} alt="Jamir Ferguson" style={imageStyle} />  {/* Add this line after the heading */}

      <div style={{ marginBottom: '40px' }}>
        <Link to="/posts" style={{ fontSize: '18px', color: '#0000EE', textDecoration: 'none' }}>Posts</Link>
      </div>



      <div style={sectionTitleStyle}>Current Interests:</div>
      <ul style={listStyle}>
        <li style={listItemStyle}>
          - Founder & Developer of 
          <a href="https://cvanalyst.io" style={linkStyle} target="_blank" rel="noopener noreferrer"> Cvanalyst</a>, 
          <a href="https://mapreads.com/explore" style={linkStyle} target="_blank" rel="noopener noreferrer"> Mapreads</a>, & Kiko
        </li>
        <li style={listItemStyle}>
          - Active on 
          <a href="https://x.com/_jamiroo" style={linkStyle} target="_blank" rel="noopener noreferrer">Twitter</a>, 
          <a href="https://www.youtube.com/@jamiro_999" style={linkStyle} target="_blank" rel="noopener noreferrer">YouTube</a>, and 
          <a href="https://www.instagram.com/mirocantcode/" style={linkStyle} target="_blank" rel="noopener noreferrer">Instagram</a>
        </li>
        <li style={listItemStyle}>- APIS, AI, & Software Development</li>
      </ul>
            

      <div style={sectionTitleStyle}>Reading:</div>
      <ul style={listStyle}>
        <li style={listItemStyle}>- <a href="#" style={linkStyle}>Mastery</a> by Robert Greene</li>
        <li style={listItemStyle}>- <a href="#" style={linkStyle}>The beginning of infinity</a>, by David Deutsch.</li>
        <li style={listItemStyle}>- <a href="#" style={linkStyle}>Unscripted</a> by MJ DeMarco</li>
        <li style={listItemStyle}>- <a href="#" style={linkStyle}>The Black Swan</a> by Nassim Nicholas Taleb</li>
      </ul>

      <div style={sectionTitleStyle}>Academic Background:</div>
      <ul style={listStyle}>
        <li style={listItemStyle}>- B.S. In Business & Information Systems, Indiana Institute of Technology</li>
      </ul>

      <div style={sectionTitleStyle}>Corporate Background:</div>
      <ul style={listStyle}>
      <li style={listItemStyle}>- Business Analyst, Startup, FL</li>
        <li style={listItemStyle}>- Data Analyst, Fortune 20 Healthcare, FL</li>
        <li style={listItemStyle}>- Data Architect, Fortune 100 Insurance, FL</li>
        <li style={listItemStyle}>- Quit for full-time entrepreneurship - 2024</li>
      </ul>

      <div style={sectionTitleStyle}>Entrepreneurial Background:</div>
      <ul style={listStyle}>
        <li style={listItemStyle}>- Mapreads, book discovery platform for book lovers</li>
        <li style={listItemStyle}>- CVAnalyst, AI-powered platform for job seekers</li>
        <li style={listItemStyle}>- KikoAI, AI platform for affiliate marketers</li>
        <li style={listItemStyle}>- CreatorWrld, SaaS for influencer discovery and outreach</li>
        <li style={listItemStyle}>- Custom SaaS, white-labeling software to influencers in the fitness industry.</li>
        <li style={listItemStyle}>- MiroFitness, failed</li>
        <li style={listItemStyle}>- Matepilot, failed</li>
        <li style={listItemStyle}>- Dataremotely, failed</li>
      </ul>

      <div style={sectionTitleStyle}>Personal:</div>
      <ul style={listStyle}>
        <li style={listItemStyle}>- Lives in Miami, Florida</li>
      </ul>

      </div>

  );
};

export default Jamiro;