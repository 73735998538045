// src/components/Launchpad.js
import React from 'react';
import MiroImage from '../images/miro.JPG';

const Launchpad = () => {
  return (
    <div style={styles.container}>
      <div style={styles.sidebar}>
        <img src={MiroImage} alt="Jamir Ferguson" style={styles.image} />
        <h2 style={styles.name}>Jamir Ferguson</h2>
        <p style={styles.title}>Developer & Founder</p>
      </div>
      <div style={styles.content}>
        <h1 style={styles.mainTitle}>The Can't Code Launchpad</h1>
        <p style={styles.subtitle}>
          The launchpad to your first completed SaaS project and income online.
        </p>
        <h1 style={styles.price}>$197</h1>
        <p style={styles.text}>
          “I want to start coding, but I don’t know where to begin.”
        </p>
        <p style={styles.text}>
          I get it.
        </p>
        <p style={styles.text}>
          When you think about coding or building an online project, it can feel overwhelming.
        </p>
        <p style={styles.text}>
          Do you need to learn how to code from scratch?
        </p>
        <p style={styles.text}>
          Should you master a programming language?
        </p>
        <p style={styles.text}>
          Do you need a $10,000 course to succeed?
        </p>
        <p style={styles.text}>
          You’ve probably seen countless tutorials, gurus, and “experts” throwing jargon at you.
        </p>
        <p style={styles.text}>
          But none of them speak to you. The truth?
        </p>
        <p style={styles.text}>
          You don’t need to know everything about coding.
        </p>
        <p style={styles.text}>
          You don’t need to build the next billion-dollar app.
        </p>
        <p style={styles.text}>
          What you need is a system to get started.
        </p>
        <p style={styles.text}>
          Here’s what I learned after failing for years.
        </p>
        <p style={styles.text}>
          I’ve built multiple SaaS products and left a 6-figure job.
        </p>
        <p style={styles.text}>
          But before all that, I had no idea what I was doing.
        </p>
        <p style={styles.text}>
          I wasted months trying to figure out: Should I learn to code everything from scratch?
        </p>
        <p style={styles.text}>
          How do APIs work?
        </p>
        <p style={styles.text}>
          What’s the fastest way to just start building something?
        </p>
        <p style={styles.text}>
          Here’s the thing:
        </p>
        <p style={styles.text}>
          You don’t need to master coding to build something impactful.
        </p>
        <p style={styles.text}>
          What you need is:
        </p>
        <ul style={styles.list}>
          <li>A clear starting point – so you don’t waste time guessing what to learn.</li>
          <li>The right tools – to simplify coding and take the overwhelm out of building.</li>
          <li>A roadmap to your first project – so you can get small wins fast and build momentum.</li>
        </ul>
        <h2 style={styles.subtitle}>Introducing: The “Can’t Code” Launchpad</h2>
        <p style={styles.text}>
          The realistic way to get started, build your first project, and use AI to simplify coding.
        </p>
        <h3 style={styles.sectionTitle}>What You’ll Achieve</h3>
        <ul style={styles.list}>
          <li>Understand how APIs and AI tools like Cursor make coding approachable.</li>
          <li>Build your first functional project using my exact tech stack (Python, FastAPI, RapidAPI).</li>
          <li>Deploy your project online, ready to share or monetize.</li>
          <li>Get the confidence to tackle bigger projects in the future.</li>
        </ul>
        <h3 style={styles.sectionTitle}>Here’s What You’ll Learn</h3>
        <ol style={styles.list}>
          <li>Welcome to the “Can’t Code” Club</li>
          <li>APIs and AI: Your Secret Weapons</li>
          <li>Build the Backend with FastAPI</li>
          <li>Add a Frontend Without Overthinking It</li>
          <li>Deploy Your Project and Go Live</li>
          <li>Monetize Your Project</li>
          <li>Launch and Share Your Work</li>
        </ol>
        <h3 style={styles.sectionTitle}>Why This Launchpad Works</h3>
        <p style={styles.text}>
          No Fluff, Just Action: You’ll build a project from Day 1. Powered by AI: Learn how to use tools like Cursor to code smarter, not harder.
          Simple Yet Scalable: Start small, but with a foundation you can grow from.
        </p>
        <h3 style={styles.sectionTitle}>Bonuses</h3>
        <ul style={styles.list}>
          <li>Project Templates: Skip the setup and start building with my API-ready boilerplate.</li>
          <li>AI Cheat Sheets: My top prompts and strategies for using Cursor and other AI tools to write and debug code faster.</li>
          <li>Step-by-Step Deployment Guide: Ensure your project is live and ready to share.</li>
        </ul>
        <h3 style={styles.sectionTitle}>This Launchpad Is For You If…</h3>
        <ul style={styles.list}>
          <li>You’ve always wanted to build something, but feel overwhelmed by coding.</li>
          <li>You’re stuck in tutorial hell, unsure how to turn knowledge into action.</li>
          <li>You want a simple, realistic system to create and deploy your first project.</li>
        </ul>
        <h3 style={styles.sectionTitle}>What’s the Investment?</h3>
        <p style={styles.text}>$197</p>
        <p style={styles.text}>
          That’s it. No subscriptions. No upsells. Just a clear, actionable system to get started.
        </p>
        <h3 style={styles.sectionTitle}>Take the First Step Today</h3>
        <p style={styles.text}>
          This isn’t about quitting your job tomorrow or making $100k/month. It’s about building something tangible, proving to yourself that you can do it, and setting yourself up for long-term wins.
        </p>
        <p style={styles.text}>
          The first project is the hardest. But after this, you’ll never have to wonder “where do I start?” again.
        </p>
        <p style={styles.text}>
          All-in-all… I created this course for my past self. This is the course I wish I had so I didn't waste my time failing at 7 business models that didn't fulfill me. This is what I would do if I wanted to fast-track my way to $5K and beyond.
        </p>
        <p style={styles.text}>
          If you want to stop living in your head wondering when and if you should start the damn thing, maybe now is the perfect opportunity.
        </p>
        <p style={styles.text}>
          Enroll below when you're ready.
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
  sidebar: {
    position: 'sticky',
    top: '20px',
    textAlign: 'center',
    marginRight: '40px',
  },
  image: {
    width: '150px',
    borderRadius: '50%',
  },
  name: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '10px 0',
  },
  title: {
    fontSize: '16px',
    color: '#666',
  },
  content: {
    maxWidth: '600px',
  },
  mainTitle: {
    fontSize: '40px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '20px',
    fontStyle: 'italic',
    marginBottom: '20px',
  },
  price: {
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  text: {
    fontSize: '18px',
    lineHeight: '1.8',
    marginBottom: '10px',
  },
  list: {
    marginBottom: '20px',
    paddingLeft: '20px',
  },
  sectionTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '10px',
  },
};

export default Launchpad;