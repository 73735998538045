// src/components/PostDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import posts from '../posts/posts.json';

const PostDetail = () => {
  const { id } = useParams();
  const post = posts.find((p) => p.id.toString() === id); // Ensure id is compared as a string

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '40px 20px', fontFamily: 'sans-serif', color: '#333', backgroundColor: '#fff' }}>
      <h1 style={{ fontSize: '40px', fontWeight: 'normal', margin: '0 0 20px 0', lineHeight: '1.1' }}>{post.title}</h1>
      <p style={{ fontSize: '18px', lineHeight: '1.5', whiteSpace: 'pre-wrap' }}>{post.content}</p>
    </div>
  );
};

export default PostDetail;