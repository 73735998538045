// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Jamiro from './components/Jamiro';
import PostList from './components/PostList';
import PostDetail from './components/PostDetail';
import Launchpad from './components/Launchpad';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Jamiro />} />
        <Route path="/posts" element={<PostList />} />
        <Route path="/posts/:id" element={<PostDetail />} />
        <Route path="/launchpad" element={<Launchpad />} />
      </Routes>
    </Router>
  );
}

export default App;